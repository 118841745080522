export const PRODUCT_AVAILABLE_LABELS = {
  name: "NAME",
  description: "DESCRIPTION",
  image: "IMAGE",
  feature: "FEATURE",
  featureIcon: "FEATURE_ICON",
  icon: "ICON",
}

export const PRODUCT_PRE_LABEL = "PRODUCT_MANAGEMENT_";


export const AVAILABLE_STEPS = [
  { id: 1, name: 'months', productId: 'bms', title: 'offer.selectOfferPeriod' },
  { id: 2, name: 'price', productId: 'bms', title: 'offer.selectOfferPrice' },
  { id: 3, name: 'devices', productId: 'bms', title: 'offer.selectOfferDevices' },
  { id: 1, name: 'months', productId: 'tsmd', title: 'offer.selectOfferPeriod' },
  { id: 2, name: 'devices', productId: 'tsmd', title: 'offer.selectOfferDevices' },
  { id: 3, name: 'price', productId: 'tsmd', title: 'offer.selectOfferPrice' },
  { id: 1, name: 'months', productId: 'iosprotection', title: 'offer.selectOfferPeriod' },
  { id: 2, name: 'price', productId: 'iosprotection', title: 'offer.selectOfferPrice' },
  { id: 1, name: 'months', productId: 'soho', title: 'offer.selectOfferPeriod' },
  { id: 2, name: 'devices', productId: 'soho', title: 'offer.selectOfferDevices' },
  { id: 3, name: 'price', productId: 'soho', title: 'offer.selectOfferPrice' }
];