import React from "react";

import { useFilteredLabels, useValidateRTL } from "../../utils/hooks/useCommon";
import { PRODUCT_AVAILABLE_LABELS, PRODUCT_PRE_LABEL } from "../../utils/constants";

const ProductDescription = ({ product, productName, imageBaseUrl, productKey }) => {
  const isRTL = useValidateRTL();
  const descriptionLabels = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.description,
    product.i18nLabels
  ).filter((label) => !label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  return (
    <div className={`flex flex-col gap-2 ${isRTL ? 'rtl' : ''}`}>
      <div className="flex flex-row gap-2">
        <div className="flex-1">
          {descriptionLabels.map((label) => (
            <div key={label} className="my-2 flex flex-row gap-2 relative">
              <img
                src={`${imageBaseUrl}/bitdefender/svg/check.svg`}
                alt={label}
                className={`absolute top-1 ${isRTL ? 'right-0' : 'left-0'}`}
              />
              <span className={`text-sm ${isRTL ? 'mr-5' : 'ml-5'}`}>{`${product.labels[
                `${PRODUCT_PRE_LABEL}${productKey}_${label}`
              ]}`}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
