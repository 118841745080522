import { useTranslation } from 'react-i18next';
import { PRODUCT_PRE_LABEL } from '../constants';

export const useValidateRTL = () => {
  const { i18n } = useTranslation();
  return i18n.language === 'ar';
};

export const useGetLabelTranslation = (productId, label, labelList) => {
  const localProductId = productId.replace(/-/g, "_").toUpperCase();
  const key = Object.keys(labelList).find(
    key => key === `${PRODUCT_PRE_LABEL}${localProductId}_${label.toUpperCase()}`
  );
  return key ? labelList[key] : undefined;
};

export const useFilteredLabels = (word, labelList) => {
  const parts = word.split("_");
  return labelList.split(", ").filter((value) => {
    return parts.every((part) =>
      value.toLowerCase().includes(part.toLowerCase())
    );
  });
};