import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar from "./locales/ar.json";
import fr from "./locales/fr.json";

i18n.use(initReactI18next).init({
  lng: "fr",
  fallbackLng: "fr",
  resources: { fr, ar },
  loadPath: '/src/locales', // Ruta a los archivos de traducción
  interpolation: {
    escapeValue: false,
  },
});

export const availableLanguages = [
  { code: 'fr', name: 'FR' },
  { code: 'ar', name: 'ع' },
];

export default i18n;