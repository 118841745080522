import Cookies from 'js-cookie';

const userCookieMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();
  
  // Verifica si la acción afectó al estado del usuario
  if (action.type.startsWith('user/')) {
    // Guarda todo el estado del usuario en una cookie
    Cookies.set('userData', JSON.stringify(state.user), { expires: 7 }); // Expira en 7 días
  }
  
  return result;
};

export default userCookieMiddleware;
