import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http/axiosConfig';

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (payload, { rejectWithValue }) => {
    try {
      let url = `/product/list/${payload.language}`;
      if (payload.msisdn && payload.msisdn !== '') {
        url += `?msisdn=${payload.msisdn}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProduct = createAsyncThunk(
  'products/getProduct',
  async (payload, { rejectWithValue }) => {
    try {
      let url = `/product/get/${payload.productId}/${payload.language}`;
      if (payload.msisdn && payload.msisdn !== '') {
        url += `?msisdn=${payload.msisdn}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postData = createAsyncThunk(
  'api/postData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('https://api.example.com/data', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    products: [],
    loading: false,
    error: null,
    selectedProduct: null,
  },
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = state.products.find(product => product.productId === action.payload);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedProduct = action.payload;
      })
      
  },
});

export const { setSelectedProduct, setLoading } = offerSlice.actions;
export default offerSlice.reducer;