import React, { useEffect } from 'react';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import { loadUserFromCookie } from './redux/slices/userSlice';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Header from "./components/common/Header";
import { setLanguage } from './redux/slices/designSlice';
import i18n from './i18n';
import Verification from './pages/Verification';
import OfferPage from './pages/OfferPage';
import NotFound from './pages/NotFound';
import ConfirmOfferPage from './pages/ConfirmOfferPage';
import MyAccountPage from './pages/MyAccountPage';
import ConfirmCancelSubscriptionPage from './pages/ConfirmCancelSubscriptionPage';


const firebaseConfig = {
  apiKey: "AIzaSyCk8mz7KZzeKOZLcZy4BvOsFQ8CnnSIiF8",
  authDomain: "bitdefender-frontend.firebaseapp.com",
  projectId: "bitdefender-frontend",
  storageBucket: "bitdefender-frontend.appspot.com",
  messagingSenderId: "253276021597",
  appId: "1:253276021597:web:d71fc56869be078a6a7eb4",
  measurementId: "G-7SFNFM48MF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Habilitar Analytics en desarrollo (opcional)
if (process.env.REACT_APP_NODE_ENV === 'development') {
  window.FIREBASE_ANALYTICS_DEBUG_MODE = true;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserFromCookie());
    
    // Registrar un evento de inicio de aplicación
    logEvent(analytics, 'app_start');
  }, [dispatch]);

  useEffect(() => {
    const defaultLanguage = i18n.language || 'en';
    dispatch(setLanguage(defaultLanguage));
    
    // Registrar el idioma como un evento
    logEvent(analytics, 'set_language', { language: defaultLanguage });
  }, [dispatch]);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/product/:productId" element={<OfferPage />} />
        <Route path="/confirm-offer/:productId/:offerId" element={<ConfirmOfferPage />} />
        <Route path="/my-account" element={<MyAccountPage />} />
        <Route path="/confirm-cancel-subscription/:productId/:offerId" element={<ConfirmCancelSubscriptionPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
