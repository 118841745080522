import React from "react";
import { useNavigate } from "react-router-dom";

import LanguageSelector from "./LanguageSelector";

const Header = ({
  showTranslation = true,
  closable = false,
  closeAction = () => {},
  styles = {},
}) => {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const navigate = useNavigate();
  const handleBackHome = () => {
    navigate('/');
  };
  return (
    <header className="bg-white p-4 flex justify-between w-full ltr shadow-md" style={styles}>
      <div className="flex items-center">
        {closable && (
          <div className="cursor-pointer" onClick={closeAction}>
            <img
              src={(`${imageBaseUrl}/bitdefender/svg/close_icon.svg`)}
              alt="Close Icon"
              className="h-5 inline mr-2"
            />
          </div>
        )}
        <img
          onClick={handleBackHome}
          src={(`${imageBaseUrl}/bitdefender/svg/ooredo_logo.svg`)}
          alt="Company Logo"
          className="h-5 inline"
        />
        <div className="text-gray-300 text-2xl mx-2">|</div>
        <img
          onClick={handleBackHome}
          src={(`${imageBaseUrl}/bitdefender/svg/bitdefender_logo.svg`)}
          alt="Antivirus logo"
          className="h-4 inline"
        />
      </div>
      {showTranslation && <LanguageSelector />}
    </header>
  );
};

export default Header;
