import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http/axiosConfig';
import Cookies from 'js-cookie';

export const getIp = createAsyncThunk(
  'user/getIp',
  async (_, { rejectWithValue }) => {
    try {
      let url = 'https://api.ipify.org?format=json';
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postRequestOTP = createAsyncThunk(
  'user/postRequestOTP',
  async (data, { rejectWithValue }) => {
    try {
      let url = '/adsafe/auth/otp/request';
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data.error || 'Erreur de demande'
      });
    }
  }
);

export const postValidateOTP = createAsyncThunk(
  'user/postValidateOTP',
  async (data, { rejectWithValue }) => {
    try {
      let url = '/adsafe/auth/otp/validation';
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data.error || 'Erreur de demande'
      });
    }
  }
);

export const postLogout = createAsyncThunk(
  'user/postLogout',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      let url = '/adsafe/auth/logout';
      const response = await axios.post(url, data);
      dispatch(setIsLoggedOut());
      Cookies.remove('userData');
      return response.data;
    } catch (error) {
      Cookies.remove('userData');
      dispatch(setIsLoggedOut());
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data.error || 'Erreur de déconnexion'
      });
    }
  }
);


const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: null,
    msisdn: "",
    isLoggedIn: false,
    isWrongCode: false,
    ip: "",
    otpRequestId: "",
    authCode: "",
  },
  reducers: {
    setMsisdn: (state, action) => {
      state.msisdn = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsWrongCode: (state, action) => {
      state.isWrongCode = action.payload;
    },
    setIp: (state, action) => {
      state.ip = action.payload;
    },
    setOtpRequestId: (state, action) => {
      state.otpRequestId = action.payload;
    },
    setAuthCode: (state, action) => {
      state.authCode = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    loadUserFromCookie: (state) => {
      const userData = Cookies.get('userData');
      if (userData) {
        Object.assign(state, JSON.parse(userData));
      }
    },
    setIsLoggedOut: (state, action) => {
      state.isLoggedIn = false;
      state.msisdn = "";
      state.authCode = "";
      state.otpRequestId = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIp.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIp.fulfilled, (state, action) => {
        state.loading = false;
        state.ip = action.payload.ip;
      })
      .addCase(getIp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postRequestOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRequestOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.otpRequestId = action.payload.otpRequestId;
        state.error = null;
      })
      .addCase(postRequestOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.otpRequestId = null;
      })
      .addCase(postValidateOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(postValidateOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.msisdn = action.payload.userId;
        state.authCode = action.payload.authCode;
        state.error = null;
      })
      .addCase(postValidateOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.authCode = "";
        state.isWrongCode = true;
      })
      .addCase(postLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLogout.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.msisdn = "";
        state.authCode = "";
        state.otpRequestId = "";
        state.error = null;
      })
      .addCase(postLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});
export const { setMsisdn, setIsLogged, setIsWrongCode, setIp, setOtpRequestId, setAuthCode, setError, loadUserFromCookie, setIsLoggedOut, setIsLoggedIn } = userSlice.actions;
export default userSlice.reducer;