import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "../common/Card";
import Spinner from "../common/Spinner";

const CodeVerification = ({
  onConfirm,
  handleBackToHome,
  getNewCode,
  isRTL,
  isWrongCode,
  loading,
}) => {
  const timeLimit = 120;
  const [verificationCode, setVerificationCode] = useState(Array(4).fill(""));
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const timerRef = useRef(null);
  const { t } = useTranslation();

  const startTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(timerRef.current);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`; // Formato MM:SS
  };

  const handleInput = (index, value) => {
    if (value.length === 1) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
    }

    if (value.length === 0) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
    }
    // Mover el foco al siguiente input si se llena
    if (value.length === 1 && index < 4) {
      const nextInput = document.getElementById(`code-${index + 2}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    // Mover el foco al input anterior si se borra todo el contenido
    if (value.length === 0 && index > 0) {
      const previousInput = document.getElementById(`code-${index}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const isFormComplete = verificationCode.length === 4 && verificationCode.every(code => /^\d$/.test(code));

  const handlerGetNewCode = () => {
    setTimeLeft(timeLimit);
    setVerificationCode(Array(4).fill(""));
    getNewCode();
    startTimer();
  };

  return (
    <div className="flex flex-col items-center justify-center mt-8 mx-4">
      <Card title={t("login.set_pin_code")}>
        <div className="flex mb-2 space-x-0 items-center justify-center">
          {verificationCode.map((_, index) => (
            <div key={index}>
              <label htmlFor={`code-${index + 1}`} className="sr-only">
                Code {index + 1}
              </label>
              <input
                type="tel"
                maxLength="1"
                data-focus-input-next={`code-${index + 2}`}
                id={`code-${index + 1}`}
                className={`block w-12 h-14 py-3 text-2xl font-extrabold text-center rounded-none text-white bg-lightgray placeholder:text-white ${
                  index === 0 ? "rounded-tl-xl rounded-r-none" : ""
                } ${index === 3 ? "rounded-br-xl rounded-l-none" : ""}
                  ${isWrongCode ? "bg-primary opacity-50" : ""}
                  `}
                required
                placeholder="_"
                onChange={(e) => handleInput(index, e.target.value)}
                value={verificationCode[index]}
              />
            </div>
          ))}
        </div>
      </Card>
      <div
        className={`flex flex-col items-center text-center justify-center mt-6 mx-4 mb-6 ${
          isRTL ? "rtl" : ""
        }`}
      >
        <p className="text-sm mt-8">
          {t("login.pin_time_limit")} {formatTime(timeLeft)}
        </p>
        {isWrongCode && (
          <p className="text-sm mt-8 text-primary">{t("login.pin_wrong")}</p>
        )}
      </div>

      {/* Buttons */}
      <div className="flex-col fixed bottom-0 left-0 w-full p-4 flex justify-between items-center">
        {timeLeft === 0 && !loading && (
          <button
            onClick={handlerGetNewCode}
            className="bg-primary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold text-lg"
          >
            {t("login.get_new_code")}
          </button>
        )}
        {timeLeft > 0 && !loading && (
          <button
            onClick={isFormComplete ? () => onConfirm(verificationCode.join("")) : undefined}
            className={`text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold text-lg ${
              !isFormComplete || isWrongCode
                ? "opacity-50 cursor-not-allowed bg-gray-600"
                : "bg-primary"
            }`}
            disabled={!isFormComplete || isWrongCode}
          >
            {t("login.confirm")}
          </button>
        )}
        {loading && (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
        <button
          onClick={handleBackToHome}
          className="bg-secondary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold text-lg"
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default CodeVerification;
