import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { availableLanguages } from "../../i18n";
import { setLanguage } from "../../redux/slices/designSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.design);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    dispatch(setLanguage(selectedLanguage.toLowerCase()));
  };

  return (
    <div className="flex items-center">
      <select
        value={language}
        onChange={handleLanguageChange}
        className="bg-lightgray text-white font-bold rounded appearance-none p-2 flex items-center justify-center"
      >
        {availableLanguages.map((lang) => (
          <option key={lang.code} className="text-white text-center" value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
