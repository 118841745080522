import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { setTitle } from "../redux/slices/designSlice";
import MainMenu from "../components/MainMenu";
import Spinner from "../components/common/Spinner";
import { useValidateRTL } from "../utils/hooks/useCommon";
import {
  postUnsubscribeOffer,
} from "../redux/slices/offerSlice";
import Toast from "../components/common/Toast";
import errorImage from "../assets/images/404.png";

const ConfirmCancelSubscriptionPage = () => {
  const { productId, offerId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOffer, loading } = useSelector((state) => state.offer);
  const language = useSelector((state) => state.design.language);
  const { msisdn, ip, authCode } = useSelector((state) => state.user);
  const isRtl = useValidateRTL();
  const { i18n } = useTranslation();

  const [isOfferConfirmed, setIsOfferConfirmed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);

  const productKey = selectedOffer.productId.replace(/-/g, "_").toUpperCase();
  const productName =
  selectedOffer.labels[
      `PRODUCT_MANAGEMENT_${productKey}_${selectedOffer.i18nLabels
        .split(", ")[0]
        .toUpperCase()}`
    ];

  useEffect(() => {
    dispatch(setTitle(t("offer.confirmation")));
    if (!selectedOffer) {
      navigate("/");
    }
  }, [dispatch, productId, offerId, navigate, language]);

  const handleConfirm = async () => {
    try {
      const response = await dispatch(
        postUnsubscribeOffer({
          endpointFk: process.env.REACT_APP_ENDPOINT_FK,
          identifierFk: selectedOffer.subscribedOffer.authzOtpIdentifierId,
          landingFk: process.env.REACT_APP_LANDING_FK,
          pricepointFk: selectedOffer.subscribedOffer.authzPricepointId,
          userId: msisdn,
          userIdType: "MSISDN",
          requestId: uuidv4(),
          password: process.env.REACT_APP_PASSWORD,
          authCode: authCode,
        })
      ).unwrap();
      if (response.status === 200) {
        setIsOfferConfirmed(true);
      }
    } catch (error) {
      setShowToast(true);
      if (error.message) {
        setError(error.message);
      } else {
        setError(error.error);
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainMenu />
      <div className="flex-grow flex flex-col gap-4 mx-4 my-4 pb-24">
        <Toast
          type="error"
          message={error}
          onClose={() => setShowToast(false)}
          visible={showToast}
        />
        <div className="flex-grow flex flex-col items-center justify-center p-4 mb-16">
          <img
            src={errorImage}
            alt="Error 404"
            style={{
              width: "30vw",
              height: "auto",
            }}
            className="my-8"
          />
          <p className="text-center text-lg mb-8">
            {
              !isOfferConfirmed && (
                <Trans
                  i18nKey="offer.confirmationUnsubscribeMessage"
                  values={{ productName: productName }}
                  components={{ bold: <strong /> }}
                  key={i18n.language}
                />
              )
            }
            {
              isOfferConfirmed && (
                <p className="text-center text-lg mb-8">
                  {t("offer.confirmationUnsubscribeSuccessMessage")}
                </p>
              )
            }
          </p>
        </div>
      </div>
      {/* Buttons */}
      <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
        {!isOfferConfirmed && !loading && (
          <>
            <div className="flex flex-col gap-2">
              <button
                className="w-full bg-primary text-white font-bold text-lg py-3 rounded-lg"
                onClick={handleConfirm}
              >
                {t("login.confirm")}
              </button>
              <button
                className="bg-secondary py-3 text-white rounded-lg w-full font-bold text-xl"
                onClick={() => navigate(-1)}
              >
                {t("login.return")}
              </button>
            </div>
          </>
        )}
        {isOfferConfirmed && (
          <div className="flex flex-col gap-2">
            <button
              className="w-full bg-primary text-white font-bold text-lg py-3 rounded-lg"
              onClick={() => navigate("/")}
            >
              {t("general.buySubscription")}
            </button>
          </div>
        )}
        {loading && (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmCancelSubscriptionPage;
