import React from "react";
import { useTranslation } from "react-i18next";
import { useValidateRTL } from "../../utils/hooks/useCommon";
import { useSelector } from "react-redux";

const ProductFooter = ({ product, onProductClick }) => {
  const { t } = useTranslation();
  const isRTL = useValidateRTL();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const getLowestPrice = (offers) => {
    if (!offers || offers.length === 0) return null;
    return offers.reduce((lowest, offer) => {
      return offer.price < lowest.price ? offer : lowest;
    });
  };
  const lowestPriceOffer = getLowestPrice(product.offers);

  const isActiveOffer = false;
  return (
    <div className="flex flex-row justify-center">
      {isActiveOffer ? (
        <button className="bg-customGreen text-white font-bold px-4 py-2 rounded-full hover:bg-green-600">
          {t("product.activeOffer")}
        </button>
      ) : (
        <div className="flex flex-col justify-center text-center">
          {product.offers.find((offer) => offer.canSubscribe) && isLoggedIn ? (
            <>
              <span className="text-customTextGray text-sm mb-2">
                {t("product.fromPrice")}
              </span>
              <div
                className={`flex flex-row justify-center ${
                  isRTL ? "flex-row-reverse" : "flex-row"
                }`}
              >
                <h2 className={`text-3xl font-bold ${isRTL ? "rtl" : ""}`}>{`${
                  lowestPriceOffer.price
                } ${t("product.currency")}`}</h2>
                <span className="text-gray-400 text-lg mt-2">
                  {isRTL ? `${t("product.month")}/` : `/${t("product.month")}`}
                </span>
              </div>
            </>
          ) : null}
          {product.offers.find((offer) => offer.subscribed) && isLoggedIn ? (
            <button
              onClick={onProductClick}
              className="bg-customGreen text-white font-bold text-xl px-4 py-2 mt-2 mb-6 rounded-full hover:bg-green-600 w-full"
            >
              {t("product.activeOffer").toUpperCase()}
            </button>
          ) : product.offers.find((offer) => offer.canSubscribe) ? (
            <button
              onClick={onProductClick}
              className="bg-primary text-white font-bold text-2xl px-4 py-2 mt-2 mb-6 rounded-full hover:bg-red-800 w-48"
            >
              {t("product.buy").toUpperCase()}
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ProductFooter;
