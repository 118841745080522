import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import Cookies from "js-cookie";

import { setTitle } from "../redux/slices/designSlice";
import MainMenu from "../components/MainMenu";
import ProductHeader from "../components/products/ProductHeader";
import Spinner from "../components/common/Spinner";
import Card from "../components/common/Card";
import { useValidateRTL } from "../utils/hooks/useCommon";
import Toast from "../components/common/Toast";
import { getProducts } from "../redux/slices/productsSlice";
import { setSelectedOffer } from "../redux/slices/offerSlice";

const MyAccountPage = () => {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector((state) => state.design.language);
  const {
    loading,
  } = useSelector((state) => state.user);
  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useSelector((state) => state.products);
  const isRtl = useValidateRTL();
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);
  const [subscribedOffers, setSubscribedOffers] = useState([]);
  useEffect(() => {
    dispatch(setTitle(t("general.myAccount")));
  }, [dispatch, language]);

  useEffect(() => {
    const userData = JSON.parse(Cookies.get("userData"));
    if (!userData.isLoggedIn) {
      navigate("/");
    } else {
      const payload = {
        language: i18n.language,
        msisdn: userData.msisdn,
      };
      dispatch(getProducts(payload));
    }
  }, []);

  const getSubscribedOffers = () => {
    const results = products.reduce((acc, product) => {
      // Filtra las ofertas suscritas del producto
      const subscribedOffers = product.offers.filter(
        (offer) => offer.subscribed
      );

      // Por cada oferta suscrita, crea una entrada separada del producto
      subscribedOffers.forEach((offer) => {
        acc.push({
          ...product,
          subscribedOffer: offer, // Solo incluye esta oferta específica
        });
      });

      return acc;
    }, []);
    setSubscribedOffers(results);
    return results;
  };

  useEffect(() => {
    setSubscribedOffers(getSubscribedOffers());
  }, [products]);

  const existUnpaidOffer = () => {
    const results = subscribedOffers.filter(
      (product) => !isPaidOffer(product.subscribedOffer)
    );
    return results.length > 0;
  };

  const onConfirmCancel = (product) => {
    dispatch(setSelectedOffer(product));
    navigate(
      `/confirm-cancel-subscription/${product.productId}/${product.subscribedOffer.id}`
    );
  };

  const isPaidOffer = (subscribedOffer) => {
    const currentDate = new Date();
    const expireDate = new Date(subscribedOffer.expireTs);
    return expireDate > currentDate;
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainMenu />
      <div className="flex-grow flex flex-col gap-4 mx-4 my-4 pb-48">
        <Toast
          type="error"
          message={error}
          onClose={() => setShowToast(false)}
          visible={showToast}
        />
        {(loading || productsLoading) && (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
        {subscribedOffers.length > 0 && (
          <div>
            {subscribedOffers.map((product, index) => {
              const productName =
                product.labels[
                  `PRODUCT_MANAGEMENT_${product.productId
                    .replace(/-/g, "_")
                    .toUpperCase()}_${product.i18nLabels
                    .split(", ")[0]
                    .toUpperCase()}`
                ];
              const productKey = product.productId
                .replace(/-/g, "_")
                .toUpperCase();
              return (
                <div className="flex flex-col gap-4 mb-4" key={index}>
                  <Card childrenStyles="mt-0">
                    <ProductHeader
                      product={product}
                      imageBaseUrl={imageBaseUrl}
                      productName={productName}
                      productKey={productKey}
                      selectedDevices={product.subscribedOffer.devices}
                      selectedMonths={product.subscribedOffer.months}
                    />
                    <div
                      className={`flex flex-row justify-between gap-4 ${
                        isRtl ? "rtl" : ""
                      }`}
                    >
                      <h2 className="flex items-center text-xl font-bold">
                        {t("offer.totalPrice")}
                      </h2>
                      <div className="flex justify-between items-center bg-primary p-1 bg-opacity-10 rounded-full ltr">
                        <div className="flex items-center py-2 px-3">
                          <span
                            className={`text-xl font-bold ${
                              isRtl ? "rtl" : "ltr"
                            }`}
                          >
                            {product.subscribedOffer?.price || "0"}{" "}
                            {t("product.currency")}
                          </span>
                        </div>
                        <div className="flex gap-1 bg-primary p-4 rounded-full">
                          <img
                            src={require("../assets/icons/clock.png")}
                            alt="🕣"
                            className="w-5 h-5"
                          />
                          <div
                            className={`flex gap-1 ${isRtl ? "rtl" : "ltr"}`}
                          >
                            <span className="text-sm text-white font-bold">
                              {product.subscribedOffer?.months}
                            </span>
                            <span className="text-sm text-white font-bold">
                              {t("product.month")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex flex-row gap-4 mt-4 border-t border-gray-200 pt-4 ${
                        isRtl ? "rtl" : "ltr"
                      }`}
                    >
                      <div className="flex flex-1 items-center">
                        <span className=" font-bold text-lg">
                          {t("general.status")}
                        </span>
                      </div>
                      <div className="flex flex-1">
                        <div
                          className={`flex ${
                            isPaidOffer(product.subscribedOffer)
                              ? "bg-customGreen"
                              : "bg-yellow-500"
                          } p-1 bg-opacity-20 rounded-full w-full justify-center`}
                        >
                          <span
                            className={`py-2 px-4 text-xl font-bold text-gray-400`}
                          >
                            {isPaidOffer(product.subscribedOffer)
                              ? t("product.paid")
                              : t("product.pendingtoPay")}
                          </span>
                        </div>
                      </div>
                    </div>
                    {!isPaidOffer(product.subscribedOffer) && (
                      <div className="flex flex-row gap-4 mt-4 border-t border-gray-200 pt-4">
                        <button
                          className="bg-transparent text-gray-400 font-bold text-lg rounded-lg w-full"
                          onClick={() => onConfirmCancel(product)}
                        >
                          {t("general.cancelSubscription")}
                        </button>
                      </div>
                    )}
                  </Card>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* Buttons */}
      <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4 pt-0 bg-white">
        {!existUnpaidOffer() && (
          <div className="flex flex-col gap-4 mb-0">
            <div className="flex flex-row gap-4">
              <label className="text-center text-md py-2 rounded-lg">
                <Trans
                  i18nKey="general.bitdefenderAccountMessage"
                  values={{ platform: t("general.bitdefenderCentral") }}
                  components={{ bold: <strong /> }}
                />
              </label>
            </div>
          </div>
        )}
        {!existUnpaidOffer() && (
          <button
            className={`w-full bg-primary text-white font-bold text-lg py-3 rounded-lg mb-2`}
          >
            {t("general.bitdefenderCentral")}
          </button>
        )}
        {existUnpaidOffer() && (
          <button
            className={`w-full bg-primary text-white font-bold text-lg py-3 rounded-lg mb-2`}
            onClick={() =>
              window.open("https://apps.ooredoo.dz/e-payment/payment/public/", "_blank")
            }
          >
            {t("general.rechargeAccount")}
          </button>
        )}
        <button
          className={`w-full bg-secondary text-white font-bold text-lg py-3 rounded-lg`}
          onClick={() => navigate("/")}
        >
          {t("general.bitdefenderOffers")}
        </button>
      </div>
    </div>
  );
};

export default MyAccountPage;
