import React, { useState, useEffect } from "react";

const Toast = ({ type, message, onClose, visible }) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    if (visible) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  const getToastClass = (type) => {
    switch (type) {
      case 'success':
        return 'text-green-500 bg-white border-green-500 border-2';
      case 'error':
        return 'text-red-500 bg-white border-red-500 border-2';
      case 'warning':
        return 'text-orange-500 bg-white border-orange-500 border-2';
      default:
        return '';
    }
  };

  const getIconPath = (type) => {
    switch (type) {
      case 'success':
        return 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z';
      case 'error':
        return 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z';
      case 'warning':
        return 'M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z';
      default:
        return '';
    }
  };

  return (
    <div
      className={`fixed top-4 right-4 left-4 transition-all duration-300 ease-in-out 
                  ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}
    >
      <div
        id={`toast-${type}`}
        className={`flex items-center w-full max-w-xs p-4 text-secondary bg-white rounded-lg shadow-xl shadow-black/30 ${getToastClass(type)}`}
        role="alert"
      >
        <div className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg border-0 ${getToastClass(type)}`}>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d={getIconPath(type)} />
          </svg>
          <span className="sr-only">{type} icon</span>
        </div>
        <div className="ms-3 text-sm font-normal break-words overflow-hidden">{message}</div>
        <button
          type="button"
          className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 "
          data-dismiss-target={`#toast-${type}`}
          aria-label="Close"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
