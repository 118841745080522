import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import Card from "../common/Card";
import { useFilteredLabels, useValidateRTL } from "../../utils/hooks/useCommon";
import "./styles.css";
import {
  AVAILABLE_STEPS,
  PRODUCT_AVAILABLE_LABELS,
  PRODUCT_PRE_LABEL,
} from "../../utils/constants";
import {
  postSubscribeOffer,
  setSelectedOffer,
} from "../../redux/slices/offerSlice";
import { useDispatch } from "react-redux";
import Spinner from "../common/Spinner";

const OfferComponent = ({
  selectedProduct,
  productId,
  productKey,
  imageBaseUrl,
}) => {
  const { t } = useTranslation();
  const isRTL = useValidateRTL();
  const navigate = useNavigate();
  const [selectedMonths, setSelectedMonths] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [devicesAvailable, setDevicesAvailable] = useState([]);
  const [selectedOffer, setSelectedLocalOffer] = useState(null);
  const { ip, authCode, msisdn } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.offer);
  const dispatch = useDispatch();

  const getDevicesAvailable = (months) => {
    const listt = selectedProduct.offers
      .filter((offer) => offer.months === months)
      .map((offer) => ({
        devices: offer.devices,
        disabled: !offer.canSubscribe,
      }))
      // .filter(
      //   (item, index, self) =>
      //     index === self.findIndex((t) => t.devices === item.devices)
      // );
    setDevicesAvailable(listt);
  };

  // get Steps of current product type
  const getSteps = () => {
    const steps = AVAILABLE_STEPS.filter((step) =>
      productId.toLowerCase().includes(step.productId.toLowerCase())
    );
    return steps;
  };
  // Get months periodicity available for current product
  const monthsPeriodicityAvailable = selectedProduct.offers
    .map((offer) => ({
      month: offer.months,
      disabled: !selectedProduct.offers
        .filter(o => o.months === offer.months)
        .some(o => o.canSubscribe)
    }))
    .reduce((acc, current) => {
      const existingItem = acc.find(item => item.month === current.month);
      if (!existingItem) {
        // Si no existe, lo agregamos
        acc.push(current);
      } else if (existingItem.disabled && !current.disabled) {
        // Si existe pero el actual tiene disabled false, reemplazamos el existente
        const index = acc.findIndex(item => item.month === current.month);
        acc[index] = current;
      }
      return acc;
    }, []);

  useEffect(() => {
    devicesAvailable.length === 1 && setSelectedDevices(devicesAvailable[0].devices);
  }, [selectedMonths, devicesAvailable]);

  useEffect(() => {
    if (selectedMonths && selectedDevices) {
      const offers = selectedProduct.offers.filter((offer) => {
        return (
          offer.months === selectedMonths && offer.devices === selectedDevices
        );
      });
      setSelectedLocalOffer(offers[0]);
    }
  }, [selectedMonths, selectedDevices]);

  // Handlers
  const handleChangeMonths = (e) => {
    console.log(e.target.value);
    setSelectedMonths(parseInt(e.target.value));
    getDevicesAvailable(parseInt(e.target.value));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };
  const handleChangeDevices = (value) => {
    setSelectedDevices(parseInt(value));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  const featuresLabels = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.feature,
    selectedProduct.i18nLabels
  ).filter((label) => !label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  const featuresIcons = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.featureIcon,
    selectedProduct.i18nLabels
  ).filter((label) => label.includes(PRODUCT_AVAILABLE_LABELS.icon));

  const onSubscribeOffer = async () => {
    if (selectedOffer) {
      dispatch(setSelectedOffer(selectedOffer));
      navigate(`/confirm-offer/${productId}/${selectedOffer.id}`);
    }
  };
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center pb-20">
      <h1 className="text-2xl font-bold uppercase">{t("offer.selectOffer")}</h1>
      <div className="flex flex-col gap-4 w-full">
        {getSteps().map((step) => (
          <React.Fragment key={step.id}>
            {step.name === "months" && (
              <Card title={t(step.title)} step={step.id}>
                <div
                  className={`flex flex-col gap-2 items-start mx-3 my-3 ${
                    isRTL ? "rtl" : ""
                  }`}
                >
                  {monthsPeriodicityAvailable.map((item, index) => (
                    <label
                      key={item.month}
                      className="flex items-center justify-start my-1"
                    >
                      <input
                        type="radio"
                        name="month"
                        value={item.month}
                        className="hidden"
                        onChange={handleChangeMonths}
                        disabled={item.disabled}
                      />
                      <span
                        className={`w-6 h-6 border-2 border-black rounded-full flex items-center justify-center mr-2 ${
                          item.disabled && "opacity-50 cursor-not-allowed"
                        }`}
                      >
                        <span className="w-3 h-3 bg-primary rounded-full hidden"></span>
                      </span>
                      <span
                        className={`${isRTL ? "mr-2" : "ml-2"} text-lg ${
                          item.disabled && "opacity-50 cursor-not-allowed"
                        }`}
                      >
                        {item.month}
                      </span>
                      <span
                        className={`capitalize ${
                          isRTL ? "mr-1" : "ml-1"
                        } text-lg ${
                          item.disabled && "opacity-50 cursor-not-allowed"
                        }`}
                      >
                        {t("product.month")}
                      </span>
                    </label>
                  ))}
                </div>
              </Card>
            )}
            {step.name === "devices" &&
              devicesAvailable.length > 2 &&
              selectedProduct.multiDevCapable &&
              selectedMonths && (
                <Card title={t(step.title)} step={step.id}>
                  <div className={`flex flex-col items-center mx-3 my-3`}>
                    <div
                      className={`flex flex-row gap-4 items-center justify-center`}
                    >
                      {devicesAvailable.map((item, index) => (
                        <div
                          key={`${item.devices}-${index}`}
                          className="flex items-center"
                        >
                          <input
                            type="radio"
                            name="device"
                            value={item.devices}
                            className="hidden"
                            disabled={item.disabled}
                          />
                          <span
                            className={`w-12 h-12 border-2 text-2xl rounded-full flex items-center justify-center mr-2 cursor-pointer ${
                              item.disabled && "opacity-50 cursor-not-allowed"
                            } ${
                              selectedDevices === item.devices
                                ? "bg-primary border-primary text-white"
                                : "border-black"
                            }`}
                            onClick={() => !item.disabled && handleChangeDevices(item.devices)}
                          >
                            <span className="text-2xl font-bold">{item.devices}</span>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-row gap-2 mt-4">
                      {featuresLabels.length > 1
                        ? featuresIcons
                            .slice(1)
                            .map((icon, index) => (
                              <img
                                key={index}
                                src={`${imageBaseUrl}${
                                  selectedProduct.labels[
                                    `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                  ]
                                }`}
                                alt={icon}
                                className="w-6 h-6"
                              />
                            ))
                        : featuresIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={`${imageBaseUrl}${
                                selectedProduct.labels[
                                  `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                ]
                              }`}
                              alt={icon}
                              className="w-6 h-6"
                            />
                          ))}
                    </div>
                  </div>
                </Card>
              )}
            {step.name === "price" && selectedMonths && selectedDevices && (
              <Card title={t(step.title)} step={step.id}>
                <div className="flex flex-row gap-2 items-center justify-center mx-3 my-3">
                  {isRTL ? (
                    <>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer && t("product.currency")}
                      </h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer &&
                          selectedOffer.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer &&
                          selectedOffer.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      </h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer && t("product.currency")}
                      </h1>
                    </>
                  )}
                </div>
              </Card>
            )}
          </React.Fragment>
        ))}
        {/* <Card title={t("offer.selectOfferPeriod")} step={1}>
          <div className={`flex flex-col gap-2 items-start mx-3 my-3 ${isRTL ? "rtl" : ""}`}>
            {monthsPeriodicityAvailable.map((month, index) => (
              <label key={index} className="flex items-center justify-start my-1">
                <input
                  type="radio"
                  name="month"
                  value={month}
                  className="hidden"
                  onChange={handleChangeMonths}
                />
                <span className="w-6 h-6 border-2 border-black rounded-full flex items-center justify-center mr-2">
                <span className="w-3 h-3 bg-primary rounded-full hidden"></span>
              </span>
                <span className={`${isRTL ? "mr-2" : "ml-2"} text-lg`}>{month}</span>
                <span className={`capitalize ${isRTL ? "mr-1" : "ml-1"} text-lg`}>{t("product.month")}</span>
              </label>
            ))}
          </div>
        </Card>
        {
          selectedOffer && (
            <Card title={t("offer.selectOfferPrice")} step={2} styles="mt-3">
              <div className="flex flex-row gap-2 items-center justify-center mx-3 my-3">
                {
                  isRTL ? (
                    <>
                      <h1 className="font-bold text-4xl text-primary">{t("product.currency")}</h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="font-bold text-4xl text-primary">{selectedOffer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</h1>
                      <h1 className="font-bold text-4xl text-primary">{t("product.currency")}</h1>
                    </>
                  )
                }
              </div>
            </Card>
          )
        } */}
      </div>
      {/* Agregar un div vacío para crear espacio adicional */}
      <div className="h-20"></div>
      {/* botones */}
      <div className="fixed bottom-0 left-0 right-0 bg-white px-4 pt-0 pb-4 gap-2 flex flex-col items-center">
        {loading ? (
          <div className="flex justify-center items-center mb-2">
            <Spinner />
          </div>
        ) : (
          <button
            className={`bg-primary text-white px-4 py-3 rounded-lg w-full text-lg font-bold disabled:opacity-50 ${
              !selectedOffer ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!selectedOffer}
            onClick={() => onSubscribeOffer()}
          >
            {t("offer.select")}
          </button>
        )}
        <button
          className="bg-secondary text-white px-4 py-3 rounded-lg w-full text-lg font-bold"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default OfferComponent;
