import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http/axiosConfig';


export const postSubscribeOffer = createAsyncThunk(
  'api/postSubscribeOffer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/adsafe/authz/subscribe', data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postUnsubscribeOffer = createAsyncThunk(
  'api/postUnsubscribeOffer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/adsafe/authz/unsubscribe', data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    loading: false,
    error: null,
    selectedOffer: null,
  },
  reducers: {
    setSelectedOffer: (state, action) => {
      state.selectedOffer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSubscribeOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSubscribeOffer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postSubscribeOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postUnsubscribeOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(postUnsubscribeOffer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postUnsubscribeOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { setSelectedOffer } = offerSlice.actions;
export default offerSlice.reducer;