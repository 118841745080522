import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedProduct, getProduct } from "../redux/slices/productsSlice";
import { setTitle } from "../redux/slices/designSlice";
import MainMenu from "../components/MainMenu";
import ProductHeader from "../components/products/ProductHeader";
import OfferComponent from "../components/offer/OfferComponent";
import Spinner from "../components/common/Spinner";

const Offer = () => {
  const { productId } = useParams();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProduct, loading, products } = useSelector(
    (state) => state.products
  );
  const language = useSelector((state) => state.design.language);
  const msisdn = useSelector((state) => state.user.msisdn);
  const productKey =
    selectedProduct &&
    selectedProduct.productId.replace(/-/g, "_").toUpperCase();
  const productName =
    selectedProduct &&
    selectedProduct.labels[
      `PRODUCT_MANAGEMENT_${productKey}_${selectedProduct.i18nLabels
        .split(", ")[0]
        .toUpperCase()}`
    ];

  useEffect(() => {
    dispatch(setTitle(t("offer.title")));
    if (productId) {
      dispatch(setSelectedProduct(productId));
    } else {
      navigate("/");
    }
    if (products.length === 0) {
      dispatch(getProduct({ productId, language, msisdn: msisdn !== "" ? msisdn : null }));
    }
  }, [dispatch, productId, navigate, language]);

  return (
    <>
      <MainMenu />
      <div className="flex flex-col gap-4 mx-4 my-4">
        {loading && (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
        {selectedProduct && (
          <>
            <ProductHeader
              product={selectedProduct}
              imageBaseUrl={imageBaseUrl}
              productName={productName}
              productKey={productKey}
            />
            <OfferComponent selectedProduct={selectedProduct} productId={productId} productKey={productKey} imageBaseUrl={imageBaseUrl} />
          </>
        )}
      </div>
    </>
  );
};

export default Offer;
