import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { setSelectedProduct, getProduct } from "../redux/slices/productsSlice";
import { setTitle } from "../redux/slices/designSlice";
import MainMenu from "../components/MainMenu";
import ProductHeader from "../components/products/ProductHeader";
import Spinner from "../components/common/Spinner";
import Card from "../components/common/Card";
import { useValidateRTL } from "../utils/hooks/useCommon";
import { postSubscribeOffer } from "../redux/slices/offerSlice";
import Toast from "../components/common/Toast";

const ConfirmOfferPage = () => {
  const { productId, offerId } = useParams();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedProduct, products } = useSelector((state) => state.products);
  const { selectedOffer, loading } = useSelector((state) => state.offer);
  const language = useSelector((state) => state.design.language);
  const { msisdn, ip, authCode } = useSelector((state) => state.user);
  const isRtl = useValidateRTL();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isOfferConfirmed, setIsOfferConfirmed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);

  const productKey =
    selectedProduct &&
    selectedProduct.productId.replace(/-/g, "_").toUpperCase();
  const productName =
    selectedProduct &&
    selectedProduct.labels[
      `PRODUCT_MANAGEMENT_${productKey}_${selectedProduct.i18nLabels
        .split(", ")[0]
        .toUpperCase()}`
    ];

  useEffect(() => {
    dispatch(setTitle(t("offer.confirmation")));
    if (products.length === 0) {
      dispatch(
        getProduct({
          productId,
          language,
          msisdn: msisdn !== "" ? msisdn : null,
        })
      );
    }
    if (productId && selectedOffer) {
      dispatch(setSelectedProduct(productId));
    } else {
      navigate("/");
    }
  }, [dispatch, productId, navigate, language]);

  const handleConfirm = async () => {
    try {
      const response = await dispatch(
        postSubscribeOffer({
          endpointFk: process.env.REACT_APP_ENDPOINT_FK,
          identifierFk: selectedOffer.authzOtpIdentifierId,
          requestId: uuidv4(),
          password: process.env.REACT_APP_PASSWORD,
          userIpAddr: ip,
          authCode: authCode,
          userId: msisdn,
          userIdType: "MSISDN",
        })
      ).unwrap();
      if (response.status === 200) {
        setIsOfferConfirmed(true);
      }
    } catch (error) {
      setShowToast(true);
      setError(error.error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainMenu />
      <div className="flex-grow flex flex-col gap-4 mx-4 my-4 pb-24">
        <Toast
          type="error"
          message={error}
          onClose={() => setShowToast(false)}
          visible={showToast}
        />
        {selectedProduct && selectedOffer && (
          <>
            <Card childrenStyles="mt-0">
              <ProductHeader
                product={selectedProduct}
                imageBaseUrl={imageBaseUrl}
                productName={productName}
                productKey={productKey}
                selectedDevices={selectedOffer.devices}
                selectedMonths={selectedOffer.months}
              />
              <div
                className={`flex flex-row justify-between gap-4 ${
                  isRtl ? "rtl" : ""
                }`}
              >
                <h2 className="flex items-center text-xl font-bold">
                  {t("offer.totalPrice")}
                </h2>
                <div className={`flex justify-between items-center p-1 bg-opacity-20 rounded-full ${isOfferConfirmed ? "bg-customGreen" : "bg-primary"} ltr`}>
                  <div className="flex items-center py-2 px-4">
                    <span
                      className={`text-xl font-bold ${isRtl ? "rtl" : "ltr"}`}
                    >
                      {selectedOffer?.price || "0"} {t("product.currency")}
                    </span>
                  </div>
                  <div className="flex gap-1 bg-primary p-4 rounded-full">
                    <img
                      src={require("../assets/icons/clock.png")}
                      alt="🕣"
                      className="w-5 h-5"
                    />
                    <div className={`flex gap-1 ${isRtl ? "rtl" : "ltr"}`}>
                      <span className="text-sm text-white font-bold">
                        {selectedOffer?.months}
                      </span>
                      <span className="text-sm text-white font-bold">
                        {t("product.month")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
        {isOfferConfirmed && !loading && (
          <div className="flex flex-col items-center justify-center text-center">
            <img
              src={require("../assets/icons/check.png")}
              alt="confirm-offer"
              className="w-32 h-32"
            />
            <h2 className="text-lg mt-4">{t("offer.confirmationSuccess")}</h2>
          </div>
        )}
      </div>
      {/* Buttons */}
      <div className="fixed bottom-0 left-0 right-0 bg-transparent p-4">
        {!isOfferConfirmed && !loading && (
          <>
            <div className="flex items-center mb-4 mx-6">
              <span
                className="w-6 h-6 border-2 border-black rounded-full flex items-center justify-center mr-2 cursor-pointer"
                onClick={() => setTermsAccepted(!termsAccepted)}
              >
                {termsAccepted && (
                  <span className="w-3 h-3 bg-primary rounded-full"></span>
                )}
              </span>
              <label
                htmlFor="terms"
                className="text-md cursor-pointer"
                onClick={() => setTermsAccepted(!termsAccepted)}
              >
                <span>J'accepte les </span>
                <span className="font-bold text-primary">
                  conditions d'utilisation
                </span>
              </label>
            </div>
            <button
              className={`w-full bg-primary text-white font-bold text-lg py-3 rounded-lg ${
                !termsAccepted && "opacity-50 cursor-not-allowed"
              }`}
              disabled={!termsAccepted}
              onClick={handleConfirm}
            >
              {t("login.confirm")}
            </button>
          </>
        )}
        {isOfferConfirmed && !loading && (
          <div className="flex flex-col items-center justify-center gap-2">
            <button
              className="w-full bg-primary text-white font-bold text-lg py-3 rounded-lg"
              onClick={() => navigate("/my-account")}
            >
              {t("general.myAccount")}
            </button>
            <button
              className="w-full bg-secondary text-white font-bold text-lg py-3 rounded-lg"
              onClick={() => navigate("/")}
            >
              {t("login.return")}
            </button>
          </div>
        )}
        {loading && (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmOfferPage;
