import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

const designSlice = createSlice({
  name: 'design',
  initialState: {
    language: "en",
    product: "Bitdefender",
    title: ""
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      i18n.changeLanguage(action.payload);
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});
export const { setLanguage, setProduct, setTitle } = designSlice.actions;
export default designSlice.reducer;