import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setTitle } from '../redux/slices/designSlice';
import MainMenu from '../components/MainMenu';
import MainOffer from '../components/MainOffer';
import Products from '../components/products/Products';
import { setSelectedOffer } from '../redux/slices/offerSlice';
import { setSelectedProduct } from '../redux/slices/productsSlice';

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { product } = useSelector((state) => state.design);
  const { msisdn } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(setTitle(`${t('menu.offers')} ${product}`));
    dispatch(setSelectedOffer(null));
    dispatch(setSelectedProduct(null));
  }, [dispatch, t, product]);
  
  return (
    <div className="w-full overflow-hidden text-ellipsis">
      <MainOffer />
      <MainMenu />
      <Products />
    </div>
  );
};

export default Home;
