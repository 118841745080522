import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { getProducts, setLoading } from "../../redux/slices/productsSlice";
import ProductComponent from "./ProductComponent";
import Spinner from "../common/Spinner";
const Products = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  // Selectors
  const { products, loading, error } = useSelector((state) => state.products);

  useEffect(() => {
    const userData = JSON.parse(Cookies.get('userData'));
     if (userData) {
    const payload = {
      language: i18n.language,
      msisdn: userData.msisdn,
    };
      dispatch(getProducts(payload));
    } else {
      setLoading(true);
      setTimeout(() => {
        products.length === 0 && dispatch(getProducts(payload));
      }, 200);
    }
  }, [i18n.language]);

  if (error) return <div>Error: {JSON.stringify(error)}</div>;

  return (
    <div className="flex flex-col gap-4 mx-4 my-5">
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        products.map((product) => (
          <ProductComponent key={product.productId} product={product} />
        ))
      )}
    </div>
  );
};

export default Products;
