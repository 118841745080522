import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import errorImage from "../assets/images/404.png";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow flex flex-col items-center justify-center p-4 mb-16">
        <h1 className="text-4xl font-bold mb-8">Erreur</h1>
        <img
          src={errorImage}
          alt="Error 404"
          style={{
            width: "30vw",
            height: "auto",
          }}
          className="my-8"
        />
        <p className="text-center text-xl mb-8">
          {t("notFound.message")}
        </p>
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4">
        <button
          className="bg-secondary py-2 text-white rounded-xl w-full font-bold text-xl"
          onClick={() => navigate("/")}
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default NotFound;
